import useMediaQuery from '@material-ui/core/useMediaQuery';
import { m } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Badge from '../../molecules/Badge/Badge';
import BadgeContainer from '../../molecules/Badge/Container/BadgeContainer';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import Section from '../../molecules/Section/Section';
import { breakpoints } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import { decamelize, toCamelCase } from '../../utils/helperFunctions';
import { getParams, updateParamsSilently } from '../../utils/url/handleParams';
import CaseStudyCard from './CaseStudyCard/CaseStudyCard';
import {
  CaseStudyListContent,
  CaseStudyListWrapper,
  FilterContainer,
  Heading,
  HeadingContainer,
} from './CaseStudyListStyles';

const CaseStudyList = ({ component: { heading } }) => {
  const storiesSection = useRef(null);
  const { allCasesNew } = useStaticQuery(getData);
  const [showAll, setShowAll] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const industryRef = useRef();
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.lg})`);

  const filteredCaseStudies =
    activeFilters.length === 0
      ? allCasesNew.edges
      : allCasesNew.edges.filter(edge => {
          let displayed = false;

          edge.node.tag
            .map(item => item.name)
            .forEach(category => {
              if (activeFilters.includes(category)) {
                displayed = true;
              }
            });

          return displayed;
        });

  // List of available categories to filter from
  const caseStudyTags = useMemo(() => {
    const caseTags = [];
    const tagLists = allCasesNew.edges.map(item => item.node.tag);

    tagLists.forEach(entry =>
      entry.forEach(tag => {
        if (!caseTags.includes(tag.name)) {
          caseTags.push(tag.name);
        }
      })
    );

    return caseTags.sort();
  }, [allCasesNew.edges]);

  const initialLimit = isMobile ? 6 : 12;
  const displayedCaseStudies = showAll
    ? filteredCaseStudies
    : [...filteredCaseStudies].splice(0, initialLimit);

  const clearFilters = () => {
    setActiveFilters([]);
    setShowAll(false);
    updateParamsSilently('category', '', 'remove', 'stories', true);
  };

  const handleFilterClick = useCallback(
    category => {
      const filters = [...activeFilters];

      if (filters.includes(category)) {
        filters.splice(filters.indexOf(category), 1);
      } else {
        filters.push(category);
      }

      setActiveFilters(filters);
      setShowAll(false);

      if (filters.length === 0) {
        updateParamsSilently('category', '', 'remove', 'stories', true);
      } else {
        updateParamsSilently(
          'category',
          filters.map(filter => toCamelCase(filter)).join(','),
          'replace',
          'stories',
          true
        );
      }
    },
    [activeFilters]
  );

  useEffect(() => {
    const params = getParams(['category']);

    if (params?.category[0]) {
      const category = params?.category[0];
      const filters = category.split(',').map(camelizedName => {
        let decamelizedName = '';

        switch (camelizedName) {
          case 'wrapDetail':
            decamelizedName = 'Wrap & Detail';
            break;

          default:
            decamelizedName = decamelize(camelizedName);
            break;
        }

        return decamelizedName;
      });

      setActiveFilters(filters);
    }
  }, []);

  return (
    <CaseStudyListWrapper>
      <Section
        bgColor={colors.base.light}
        hasWave
        id="stories"
        ref={storiesSection}
      >
        <CaseStudyListContent ref={industryRef}>
          <HeadingContainer>
            <Heading>{heading}</Heading>
          </HeadingContainer>
          <FilterContainer>
            <BadgeContainer size="lg">
              {caseStudyTags.map(tag => (
                <Badge
                  badgeColor={
                    activeFilters.includes(tag)
                      ? colors.primary[50]
                      : colors.gray[50]
                  }
                  className="badge"
                  onClick={() => handleFilterClick(tag)}
                  size="sm"
                  textColor={
                    activeFilters.includes(tag)
                      ? colors.primary[500]
                      : colors.gray[800]
                  }
                >
                  {tag}
                </Badge>
              ))}
              {activeFilters.length > 0 && (
                <Badge
                  badgeColor="transparent"
                  className="badge clear-filters"
                  onClick={clearFilters}
                  preserveCasing
                  size="sm"
                  textColor={colors.primary[500]}
                >
                  Clear filters
                </Badge>
              )}
            </BadgeContainer>
          </FilterContainer>
          <div className="case-studies-list px-md-0">
            {displayedCaseStudies.map(caseStudy => {
              const contentWordCount =
                JSON.parse(caseStudy.node.contentWordCounter)?.words || 0;
              const aboutCompanyWordCount =
                JSON.parse(caseStudy.node.aboutCompanyWordCounter)?.words || 0;

              return (
                <m.div
                  key={caseStudy.node.id}
                  initial={{ opacity: 0, scale: 0, filter: 'blur(5px)' }}
                  animate={{ opacity: 1, scale: 1, filter: 'blur(0)' }}
                  exit={{ filter: 'blur(5px)', opacity: 0, scale: 0 }}
                  transition={{ type: 'tween', delay: 0.1, duration: 0.5 }}
                >
                  <CaseStudyCard
                    excerpt={caseStudy.node.excerpt}
                    image={caseStudy.node.thumbnail}
                    internalName={caseStudy.node.internalName}
                    slug={caseStudy.node.slug}
                    tags={caseStudy.node.tag}
                    wordCount={contentWordCount + aboutCompanyWordCount}
                  />
                </m.div>
              );
            })}
          </div>
          {!showAll &&
            displayedCaseStudies.length < filteredCaseStudies.length && (
              <div className="load-more-container">
                <CallToAction
                  customHandleClick={() => setShowAll(true)}
                  value="Load more"
                />
              </div>
            )}
        </CaseStudyListContent>
      </Section>
    </CaseStudyListWrapper>
  );
};

const getData = graphql`
  {
    allCasesNew: allDatoCmsCaseStudy(
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          id
          slug
          excerpt
          internalName
          title
          tag {
            id
            name
          }
          thumbnail {
            gatsbyImageData(
              imgixParams: {
                fm: "webp"
                auto: "compress"
                maxW: 1080
                fit: "clip"
                q: 35
              }
            )
            fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
              ...GatsbyDatoCmsFluid
            }
            url
            alt
          }
          aboutCompanyWordCounter
          contentWordCounter
        }
      }
    }
  }
`;

export default CaseStudyList;
